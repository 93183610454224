$fa-font-path: "/fonts";

@import "node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "node_modules/spinkit/scss/spinkit.scss";
@import "node_modules/swiper/dist/css/swiper";
@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox";

@import "./animate";
@import "./font-icons";

[v-cloak] {
  display: none;
}

.grecaptcha-badge
{
  z-index: 1;
}

.modal-dialog
{
  max-width: 600px!important;
}